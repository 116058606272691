import React from "react";
import styled from "styled-components/macro";

import {
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  Container,
  Grid,
  Typography,
  Link,
} from "@mui/material";
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import { spacing } from "@mui/system";

const Spacer = styled.div(spacing);

const Wrapper = styled.div`
  ${spacing};
  text-align: center;
`;

const TypographyOverline = styled(Typography)`
  text-transform: uppercase;
  color: ${(props) => props.theme.palette.primary.main};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
`;

const Accordion = styled(MuiAccordion)`
  border-radius: 6px;
  text-align: left;
  margin: 20px 0 !important;
  box-shadow: 0 2px 6px 0 rgba(18, 38, 63, 0.05);

  &:before {
    display: none;
  }
`;

const AccordionSummary = styled(MuiAccordionSummary)`
  padding: 0 16px;
  box-shadow: 0;
  min-height: 48px !important;

  .MuiAccordionSummary-content {
    margin: 12px 0 !important;
  }
`;

const AccordionDetails = styled(MuiAccordionDetails)`
  padding-left: 16px;
  padding-right: 16px;
`;

function Warranty() {
  return (
    <Wrapper pt={20} pb={16}>
      <img src="https://eos-gnss.com/wp-content/uploads/2021/07/header-logo.jpg" />
      <Container>
        <TypographyOverline variant="body2" gutterBottom>
          Eos Positioning Systems
        </TypographyOverline>
        <Typography variant="h2" component="h3" gutterBottom>
          Warranty information
        </Typography>
        <Typography variant="subtitle1" color="textSecondary" gutterBottom>
          Coverage, exclusions, etc.
        </Typography>
        <Spacer mb={8} />

        <Grid container alignItems="center" justifyContent="center">
          <Grid item xs={12} xl={8}>
            <Accordion expanded={true}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="faq1-content"
                id="faq1-header"
              >
                <Typography variant="subtitle1">
                  Eos Limited Warranty
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="subtitle1" color="textSecondary">
                  Eos Positioning Systems Inc. hereby warrants solely to the end
                  purchaser of the Products, subject to the exclusions and
                  procedures set forth herein below, that the Products sold to
                  such end purchaser shall be free, under normal use and
                  maintenance, from defects in material and workmanship for a
                  period of 24 months from delivery date to such end purchaser.
                  Repairs and replacement components are warranted, subject to
                  the exclusions and procedures set forth below, to be free,
                  under normal use and maintenance, from defects in material and
                  workmanship for 90 days from performance or delivery, or for
                  the balance of the original warranty period, whichever is
                  greater. Battery packs are warranted for a period of 90 days.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={true}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="faq2-content"
                id="faq2-header"
              >
                <Typography variant="subtitle1">
                  PURCHASER’S EXCLUSIVE REMEDY
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="subtitle1" color="textSecondary">
                  The end purchaser’s exclusive remedy under this warranty shall
                  be limited to the repair or replacement, at the option of Eos,
                  of any defective Products or components thereof. The end user
                  shall notify Eos or an Eos approved service center immediately
                  of any claimed defect. Repairs shall be made through an Eos
                  approved service center only.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={true}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="faq3-content"
                id="faq3-header"
              >
                <Typography variant="subtitle1">Exclusions</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="subtitle1" color="textSecondary">
                  Eos does not warrant damage occurring in transit or due to
                  misuse, abuse, improper installation, neglect, lightning (or
                  other electrical discharge) or fresh/salt water immersion of
                  Products. Repair, modification or service of Eos Products by
                  any party other than an Eos approved service center shall
                  render this warranty null and void. Eos does not warrant
                  claims asserted after the end of the warranty period. Eos does
                  not warrant or guarantee the precision or accuracy of
                  positions obtained when using Products. Products are not
                  intended for primary navigation or for use in safety of life
                  applications. The potential accuracy of Products as stated in
                  Eos literature and/or Product specifications serves to provide
                  only an estimate of achievable accuracy based on:
                  <ul>
                    <li>
                      Specifications provided by the US Department of Defense
                      for GPS Positioning,
                    </li>
                    <li>
                      GPS OEM Receiver specifications of the appropriate
                      manufacturer (if applicable), and
                    </li>
                    <li>DGPS service provider performance specifications.</li>
                  </ul>
                  Eos reserves the right to modify Products without any
                  obligation to notify, supply or install any improvements or
                  alterations to existing Products.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={true}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="faq4-content"
                id="faq4-header"
              >
                <Typography variant="subtitle1">NO OTHER WARRANTIES</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="subtitle1" color="textSecondary">
                  THE FOREGOING WARRANTY IS EXCLUSIVE OF ALL OTHER WARRANTIES,
                  WHETHER WRITTEN, ORAL, IMPLIED OR ARISING BY STATUTE, COURSE
                  OF DEALING OR TRADE USAGE, IN CONNECTION WITH THE DESIGN,
                  SALE, INSTALLATION, SERVICE OR USE OF ANY PRODUCTS OR ANY
                  COMPONENTS THEREOF, INCLUDING, BUT NOT LIMITED TO, ANY
                  WARRANTY OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR
                  PURPOSE.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={true}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="faq5-content"
                id="faq5-header"
              >
                <Typography variant="subtitle1">
                  LIMITATION OF LIABILITY
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="subtitle1" color="textSecondary">
                  THE EXTENT OF EOS’S LIABILITY FOR DAMAGES OF ANY NATURE TO THE
                  END PURCHASER OR ANY OTHER PERSON OR ENTITY WHETHER IN
                  CONTRACT OR TORT AND WHETHER TO PERSONS OR PROPERTY SHALL IN
                  NO CASE EXCEED, IN THE AGGREGATE, THE COST OF CORRECTING THE
                  DEFECT IN THE PRODUCT OR, AT EOS’S OPTION, THE COST OF
                  REPLACING THE DEFECTIVE ITEM. IN NO EVENT WILL EOS BE LIABLE
                  FOR ANY LOSS OF PRODUCTION, LOSS OF PROFITS, LOSS OF USE OR
                  FOR ANY SPECIAL, INDIRECT, INCIDENTAL, CONSEQUENTIAL OR
                  CONTINGENT DAMAGES, EVEN IF EOS HAS BEEN ADVISED OF THE
                  POSSIBILITY OF SUCH DAMAGES. WITHOUT LIMITING THE FOREGOING,
                  EOS SHALL NOT BE LIABLE FOR ANY DAMAGES OF ANY KIND RESULTING
                  FROM INSTALLATION, USE, QUALITY, PERFORMANCE OR ACCURACY OF
                  ANY PRODUCTS.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={true}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="faq5-content"
                id="faq5-header"
              >
                <Typography variant="subtitle1">
                  GOVERNING LEGISLATION
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="subtitle1" color="textSecondary">
                  To the greatest extent possible, this warranty shall be
                  governed by the laws of the Province of Quebec (Canada). In
                  the event that any provision hereof is held to be invalid by a
                  court of competent jurisdiction, such provision shall be
                  severed from this warranty and the remaining provisions shall
                  remain in full force and effect.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={true}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="faq5-content"
                id="faq5-header"
              >
                <Typography variant="subtitle1">
                  OBTAINING WARRANTY SERVICE
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="subtitle1" color="textSecondary">
                  In order to obtain warranty service, the end purchaser must
                  bring the Product to an Eos approved dealer, along with the
                  end purchaser’s proof of purchase.
                </Typography>
                <br />
                <Typography variant="subtitle1" color="textSecondary">
                  In the United States, use this link to initiate the warranty
                  service process.
                  <Link
                    href="https://support.gps-mapping.com/rma"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    https://support.gps-mapping.com/rma
                  </Link>
                </Typography>
                <br />
                <Typography variant="subtitle1" color="textSecondary">
                  For any questions regarding warranty service or to obtain
                  information on warranty service outside of the United States,
                  contact Eos at the following address:
                </Typography>
                <br />
                <Typography>
                  Eos Positioning Systems Inc.
                  <br />
                  1181 Rue de l’Express
                  <br />
                  Terrebonne, Quebec, Canada J6W 0A2
                  <br />
                  Telephone number: +1(450) 824-3325
                  <br />
                  E-mail address: info@eos-gnss.com
                  <br />
                  Web site: www.eos-gnss.com
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </Container>
    </Wrapper>
  );
}

export default Warranty;
