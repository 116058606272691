import React from "react";
import styled from "styled-components/macro";
import { rgba } from "polished";

import { Box, Container, Grid, Typography } from "@mui/material";
import { spacing } from "@mui/system";

import {
  Clock as ClockIcon,
  Truck as TruckIcon,
  Tool as ToolIcon,
  CheckCircle as CheckCircleIcon,
  Send as SendIcon,
} from "react-feather";

const Wrapper = styled.div`
  ${spacing};
  background: ${(props) => props.theme.palette.background.paper};
  text-align: center;
`;

const TypographyOverline = styled(Typography)`
  text-transform: uppercase;
  color: ${(props) => props.theme.palette.primary.main};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
`;

const FeatureWrapper = styled.div`
  display: flex;
  text-align: left;
  padding: 18px 20px;
`;

const FeatureIcon = styled.div`
  svg {
    flex-shrink: 0;
    width: auto;
    height: 48px;
    width: 48px;
    background: ${(props) => rgba(props.theme.palette.primary.main, 0.15)};
    color: ${(props) => props.theme.palette.primary.main};
    padding: 10px;
    border-radius: 50%;
  }
`;

const Feature = ({ Icon, title, description }) => {
  return (
    <Grid item xs={12} sm={6} md={4} lg={4}>
      <FeatureWrapper>
        <FeatureIcon>
          <Icon />
        </FeatureIcon>
        <Box ml={6}>
          <Typography variant="h4" gutterBottom>
            {title}
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
            {description}
          </Typography>
        </Box>
      </FeatureWrapper>
    </Grid>
  );
};

function Features() {
  return (
    <Wrapper py={20}>
      <Container>
        <TypographyOverline variant="body2" gutterBottom>
          What to expect
        </TypographyOverline>
        <Typography variant="h2" component="h3" gutterBottom>
          The RMA Process
        </Typography>
        <Box mb={8} />
        <Grid container spacing={6}>
          <Feature
            Icon={SendIcon}
            title="1. Request RMA"
            description="Enter the serial number in the RMA request form to begin the process."
          />
          <Feature
            Icon={ClockIcon}
            title="2. Wait for RMA Approval"
            description="Look for an email with the RMA number and instructions for sending the equipment to the repair center."
          />
          <Feature
            Icon={TruckIcon}
            title="3. Ship Equipment"
            description="Box the equipment and follow the instructions to ship it to the service center."
          />
          <Feature
            Icon={ClockIcon}
            title="4. Wait for the Service Evaluation"
            description="After the equipment arrives at the service center, allow two business days to receive the results of the free evaluation."
          />
          <Feature
            Icon={CheckCircleIcon}
            title="5. Approve Service Estimate"
            description="After the evaluation is complete, a summary and service estimate will be sent to the email provided. If the service is covered under warranty, there will be no cost associated with the service."
          />
          <Feature
            Icon={ToolIcon}
            title="6. Service Completed and Shipped"
            description="After the service is completed, it will be shipped back to the return address provided. Check the tracking # for delivery updates."
          />
        </Grid>
      </Container>
    </Wrapper>
  );
}

export default Features;
