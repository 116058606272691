import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components/macro";

import {
  Box,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  TextField as MuiTextField,
  Typography,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  InputAdornment,
  Grid,
  Stepper,
  Step,
  StepLabel,
  makeStyles,
  Icon,
} from "@mui/material";
import { spacing } from "@mui/system";

import { useNavigate } from "react-router-dom";

import useAuth from "../../../hooks/useAuth";
import { apiurl } from "../../../config.js";
import Progress from "../tickets/Progress";
import EvaluationDetail from "../tickets/EvaluationDetail";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const axios = require("axios").default;

const Card = styled(MuiCard)(spacing);

const Button = styled(MuiButton)(spacing);

const TextField = styled(MuiTextField)(spacing);

function ApproveService(props) {
  const [isLoading, setLoading] = React.useState(false);
  const { id } = useParams();
  const [responseBy, setResponseBy] = React.useState("");
  const [approved, setApproved] = React.useState("");
  const [updated, setUpdated] = React.useState(false);

  const navigate = useNavigate();
  const { user } = useAuth();

  useEffect(() => {
    setLoading(true);
    axios.get(apiurl + "/tickets/" + id + "/approved/").then((response) => {
      console.log(response);
      setApproved(response.data.approved);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    setLoading(true);
    axios.get(apiurl + "/tickets/" + id + "/approved/").then((response) => {
      console.log(response);
      setApproved(response.data.approved);
      setLoading(false);
    });
  }, [updated]);

  function handleResponse(approvedChoice) {
    setLoading(true);
    console.log("Setting ticket status to ", approvedChoice);
    axios
      .post(apiurl + "/tickets/" + id + "/approved/", {
        service_response_by: responseBy,
        service_approved: approvedChoice,
      })
      .then((response) => {
        console.log(response);
        setLoading(false);
        setUpdated(true);
        navigate("/ticket/" + id + "/approveservice/");
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }

  function ApprovalUI() {
    return (
      <React.Fragment>
        <Typography variant="body2" gutterBottom>
          Approve the service order for your device by signing your name.
        </Typography>
        <TextField
          autoFocus
          name="approved_by"
          id="approved_by"
          label="Approved by"
          value={responseBy}
          error={!responseBy}
          fullWidth
          onChange={(e) => {
            setResponseBy(e.target.value);
          }}
          variant="outlined"
          my={2}
        />
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            type="submit"
            variant="contained"
            color="error"
            onClick={() => {
              handleResponse(false);
            }}
            disabled={!responseBy}
          >
            Reject
          </Button>
          &nbsp;
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={() => {
              handleResponse(true);
            }}
            disabled={!responseBy}
          >
            Approve
          </Button>
        </Box>
      </React.Fragment>
    );
  }

  function FinishedUI() {
    return (
      <React.Fragment>
        <Box display="flex" my={6}>
          <Typography variant="body2" gutterBottom>
            Service has been approved. Check your email for updates.
          </Typography>
          <CheckCircleIcon size="large" color="success"></CheckCircleIcon>
        </Box>
      </React.Fragment>
    );
  }

  console.log("|| approved: ", approved);
  return (
    <Box display="flex" justifyContent="center" my={6}>
      <Card mb={6}>
        <CardContent>
          <Typography variant="h1" gutterBottom>
            DMG Service Approval
          </Typography>
          {isLoading ? (
            <Box display="flex" justifyContent="center" my={6}>
              <CircularProgress />
            </Box>
          ) : approved === null || approved === false ? (
            <ApprovalUI />
          ) : (
            <FinishedUI />
          )}
        </CardContent>
      </Card>
    </Box>
  );
}

export default ApproveService;
