import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components/macro";

import {
  Box,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  TextField as MuiTextField,
  Typography,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  InputAdornment,
} from "@mui/material";
import { spacing } from "@mui/system";

import { Camera, Image } from "react-feather";

import { useNavigate } from "react-router-dom";
import { apiurl } from "../../../config.js";

import { v4 as uuidv4 } from "uuid";

const axios = require("axios").default;

const Card = styled(MuiCard)(spacing);

const Button = styled(MuiButton)(spacing);

const TextField = styled(MuiTextField)(spacing);

function PhotoList(props) {
  if (props.photos.length < 1) {
    return (
      <List dense={true}>
        <ListItem key={0}>
          <ListItemIcon>
            <Image />
          </ListItemIcon>
          <ListItemText primary="No photos" />
        </ListItem>
      </List>
    );
  } else {
    return (
      <List dense={true}>
        {props.photos.map((photo, index) => (
          <ListItem key={index}>
            <ListItemIcon>
              <Image />
            </ListItemIcon>
            <ListItemText primary={photo.name} />
          </ListItem>
        ))}
      </List>
    );
  }
}

function Receive(props) {
  const [isLoading, setLoading] = React.useState(false);
  const { id } = useParams();
  const [ticket, setTicket] = useState({});
  const [photos, setPhotos] = useState([]);
  const [tracking, setTracking] = useState("");
  const [carrier, setCarrier] = useState("Fedex");
  const [location, setLocation] = useState("");

  const navigate = useNavigate();

  function handleFileUpload(e) {
    let file = e.target.files[0];
    const newName = uuidv4();
    const name = newName + file.name.substring(file.name.lastIndexOf("."));
    file = new File([file], name, { type: file.type });
    setPhotos((photos) => [...photos, file]);
  }

  function handleSubmit() {
    setLoading(true);
    console.log("Submitting form to API...");

    axios
      .post(apiurl + "/shipments/", {
        carrier: carrier,
        tracking_number: tracking,
        images: [],
        type: "Incoming",
      })
      .then((response) => {
        updateTicket(response.data.id + "/");
        uploadPhotos(response.data.id, photos);
      })
      .then(() => {
        setLoading(false);
        navigate("/ticket/" + id);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }

  function uploadPhotos(shipment_id) {
    console.log("Photos: " + photos.length);
    console.log(photos);

    for (var i = 0; i < photos.length; i++) {
      console.log("Uploading image:");
      console.log(photos[i]);

      let data = new FormData();
      data.append("shipment", apiurl + "/shipments/" + shipment_id + "/");
      data.append("image", photos[i], shipment_id + "/" + photos[i].name);

      axios
        .post(apiurl + "/shipmentimg/", data, {
          headers: {
            accept: "application/json",
            "Accept-Language": "en-US,en;q=0.8",
            "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
          },
        })
        .then((response) => {
          //handle success
        })
        .catch((error) => {
          //handle error
        });
    }
  }

  function updateTicket(shipment_id) {
    axios
      .patch(apiurl + "/tickets/" + id + "/", {
        status: 3,
        location: location,
        equipment_received: apiurl + "/shipments/" + shipment_id,
      })
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }

  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h1" gutterBottom>
          Check-in Equipment
        </Typography>
        {isLoading ? (
          <Box display="flex" justifyContent="center" my={6}>
            <CircularProgress />
          </Box>
        ) : (
          <form onSubmit={handleSubmit}>
            <Typography variant="body2" gutterBottom>
              Scan the barcode or enter the tracking number
            </Typography>
            <FormControl m={2} sx={{ width: 0.5 }}>
              <InputLabel id="carrier-selection-label">Carrier</InputLabel>
              <Select
                name="carrier"
                value={carrier}
                labelId="carrier-selection-label"
                id="carrier"
                label="Carrier"
                error={!carrier}
                onChange={(e) => {
                  setCarrier(e.target.value);
                }}
                my={2}
              >
                <MenuItem key={0} value={"Fedex"}>
                  Fedex
                </MenuItem>
                <MenuItem key={1} value={"UPS"}>
                  UPS
                </MenuItem>
                <MenuItem key={2} value={"USPS"}>
                  USPS
                </MenuItem>
                <MenuItem key={3} value={"DHL"}>
                  DHL
                </MenuItem>
                <MenuItem key={4} value={"Pickup"}>
                  Pickup
                </MenuItem>
              </Select>
            </FormControl>
            <TextField
              autoFocus
              name="trackingNumber"
              id="trackingNumber"
              label="Tracking #"
              value={tracking}
              error={!tracking}
              fullWidth
              onChange={(e) => {
                setTracking(e.target.value);
              }}
              variant="outlined"
              my={2}
            />
            <TextField
              name="location"
              id="location"
              label="Location"
              value={location}
              error={Boolean(tracking)}
              fullWidth
              onChange={(e) => {
                setLocation(e.target.value);
              }}
              variant="outlined"
              my={2}
              error={!tracking || !carrier || !location}
            />
            <PhotoList photos={photos} />
            <Button
              variant="contained"
              component="label"
              disabled={!tracking || !carrier || !location}
            >
              <Camera style={{ marginRight: "2px" }} />
              &nbsp;Add Photo
              <input
                id="file"
                accept="image/*"
                type="file"
                hidden
                multiple
                onChange={handleFileUpload}
              />
            </Button>
            <Box
              style={{
                display: "flex",
                justifyContent: "right",
              }}
            >
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={
                  !tracking || !carrier || !location || photos.length < 1
                }
              >
                Check-in
              </Button>
            </Box>
          </form>
        )}
      </CardContent>
    </Card>
  );
}

export default Receive;
