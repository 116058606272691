import React from "react";
import * as Yup from "yup";
import styled from "styled-components/macro";
import { Field, Formik } from "formik";

import {
  Alert as MuiAlert,
  Box,
  Button as MuiButton,
  CircularProgress,
  Grid,
  TextField as MuiTextField,
  Typography,
} from "@mui/material";

import { spacing } from "@mui/system";
import MuiPhoneNumber from "material-ui-phone-number";

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

const MuiPhone = styled(MuiPhoneNumber)(spacing);

const Button = styled(MuiButton)(spacing);

const validationSchema = Yup.object().shape({
  contactName: Yup.string().required("Required"),
  companyName: Yup.string().required("Required"),
  contactPhone: Yup.string().required("Required"),
  contactEmail: Yup.string().required("Required"),
});

function ContactForm(props) {
  const handleSubmit = async (
    values,
    { setErrors, setStatus, setSubmitting }
  ) => {
    try {
      await props.handleSubmit(values);
      setStatus({ sent: true });
      setSubmitting(false);
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={props.values}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        status,
      }) => (
        <div>
          <Typography variant="h6" gutterBottom>
            Contact Information
          </Typography>
          <Typography variant="body2" gutterBottom>
            Company name and contact person for communication and service
            approval.
          </Typography>

          {status && status.sent && (
            <Alert severity="success" my={3}>
              Your data has been submitted successfully!
            </Alert>
          )}

          {isSubmitting ? (
            <Box display="flex" justifyContent="center" my={6}>
              <CircularProgress />
            </Box>
          ) : (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={6}>
                <Grid item md={6}>
                  <TextField
                    name="contactName"
                    label="Contact Name"
                    value={values.contactName}
                    error={Boolean(touched.contactName && errors.contactName)}
                    fullWidth
                    helperText={touched.contactName && errors.contactName}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={2}
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    name="companyName"
                    label="Company Name"
                    value={values.companyName}
                    error={Boolean(touched.companyName && errors.companyName)}
                    fullWidth
                    helperText={touched.companyName && errors.companyName}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={2}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={6}>
                <Grid item md={6}>
                  <TextField
                    name="contactEmail"
                    label="Email"
                    value={values.contactEmail}
                    error={Boolean(touched.contactEmail && errors.contactEmail)}
                    fullWidth
                    helperText={touched.contactEmail && errors.contactEmail}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="email"
                    variant="outlined"
                    my={2}
                  />
                </Grid>
                <Grid item md={6}>
                  <MuiPhone
                    name="contactPhone"
                    label="Phone Number"
                    value={values.contactPhone}
                    data-cy="contact-phone"
                    defaultCountry={"us"}
                    onChange={handleChange("contactPhone")}
                    onBlur={handleBlur}
                    helperText={touched.contactPhone && errors.contactPhone}
                    error={Boolean(touched.contactPhone && errors.contactPhone)}
                    fullWidth
                    type="tel"
                    variant="outlined"
                    my={2}
                  />
                </Grid>
              </Grid>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={props.previousStep}
                >
                  Back
                </Button>
                <Button type="submit" variant="contained" color="primary">
                  Save & Next
                </Button>
              </Box>
            </form>
          )}
        </div>
      )}
    </Formik>
  );
}

export default ContactForm;
