import React from "react";
import styled from "styled-components/macro";

import {
  Box,
  Button,
  Container,
  Grid,
  Typography as MuiTypography,
} from "@mui/material";
import { spacing } from "@mui/system";
import { Send as SendIcon } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const Typography = styled(MuiTypography)(spacing);

const Wrapper = styled.div`
  padding-top: 3.5rem;
  position: relative;
  text-align: center;
  overflow: hidden;
`;

const Content = styled.div`
  padding: ${(props) => props.theme.spacing(6)} 0;
  line-height: 150%;
`;

const Title = styled(Typography)`
  opacity: 0.9;
  line-height: 1.4;
  font-size: 1.75rem;
  font-weight: ${(props) => props.theme.typography.fontWeightBold};

  ${(props) => props.theme.breakpoints.up("sm")} {
    font-size: 2rem;
  }

  ${(props) => props.theme.breakpoints.up("md")} {
    font-size: 2.5rem;
  }

  span {
    color: ${(props) => props.theme.palette.secondary.main};
  }
`;

const Subtitle = styled(Typography)`
  font-size: ${(props) => props.theme.typography.h6.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};
  font-family: ${(props) => props.theme.typography.fontFamily};
  margin: ${(props) => props.theme.spacing(2)} 0;
`;

function Introduction() {
  const navigate = useNavigate();

  return (
    <Wrapper style={{ backgroundColor: "#C0CBD0" }}>
      <Container>
        <Grid container alignItems="center" justifyContent="center" spacing={4}>
          <Grid item xs={12} sm={9} md={8} lg={8}>
            <Content>
              <Title variant="h1" gutterBottom>
                DMG provides warranty and non-warranty service for all{" "}
                <span>Eos Positioning Systems</span> products sold within the
                United States
              </Title>
              <Grid container justifyContent="center" spacing={4}>
                <Grid item xs={12} lg={10}>
                  <Subtitle color="textPrimary">
                    Equipment evaluations are free.
                  </Subtitle>
                </Grid>
                <Grid item xs={12} lg={10}>
                  <Subtitle color="textSecondary">
                    Use this portal to request equipment repair or check the
                    status of a repair in progress. Simply plugin the serial
                    number or RMA number in the form below.
                  </Subtitle>
                </Grid>
              </Grid>
              <br />
              <Box my={1}>
                <Button
                  variant="contained"
                  color="secondary"
                  size="large"
                  onClick={() => {
                    navigate("/rma");
                  }}
                >
                  <SendIcon />
                  <span>&nbsp;</span>
                  Request RMA
                </Button>
              </Box>
            </Content>
          </Grid>
        </Grid>
      </Container>
    </Wrapper>
  );
}

export default Introduction;
