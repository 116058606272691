import React from "react";
import styled from "styled-components/macro";

import {
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  Container,
  Grid,
  Typography,
  List,
  ListItemText,
} from "@mui/material";
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import { spacing } from "@mui/system";

import { Link } from "react-router-dom";

const Spacer = styled.div(spacing);

const Wrapper = styled.div`
  ${spacing};
  text-align: center;
`;

const TypographyOverline = styled(Typography)`
  text-transform: uppercase;
  color: ${(props) => props.theme.palette.primary.main};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
`;

const Accordion = styled(MuiAccordion)`
  border-radius: 6px;
  text-align: left;
  margin: 20px 0 !important;
  box-shadow: 0 2px 6px 0 rgba(18, 38, 63, 0.05);

  &:before {
    display: none;
  }
`;

const AccordionSummary = styled(MuiAccordionSummary)`
  padding: 0 16px;
  box-shadow: 0;
  min-height: 48px !important;

  .MuiAccordionSummary-content {
    margin: 12px 0 !important;
  }
`;

const AccordionDetails = styled(MuiAccordionDetails)`
  padding-left: 16px;
  padding-right: 16px;
`;

function Troubleshooting() {
  return (
    <Wrapper pt={20} pb={16}>
      <Container>
        <TypographyOverline variant="body2" gutterBottom>
          Troubleshooting
        </TypographyOverline>
        <Typography variant="h2" component="h3" gutterBottom>
          Basic troubleshooting for Eos Arrow receivers
        </Typography>
        <Typography variant="subtitle1" color="textSecondary" gutterBottom>
          Save time lost during shipping, evaluation, and service by performing
          basic troubleshooting steps.
        </Typography>
        <Spacer mb={8} />

        <Grid container alignItems="center" justifyContent="center">
          <Grid item xs={12} xl={8}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="faq1-content"
                id="faq1-header"
              >
                <Typography variant="subtitle1">
                  GNSS Receiver not tracking satellites
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <List component="nav" dense={true}>
                  <ListItemText>
                    1. Connect the antenna cable to GNSS receiver and antenna.
                  </ListItemText>
                  <ListItemText>
                    2. Wiggle the antenna cable near each connection. The
                    connectors shouldn't pivot.
                  </ListItemText>
                  <ListItemText>
                    3. Place the receiver and antenna outside with the antenna
                    facing up.
                  </ListItemText>
                  <ListItemText>
                    4. Turn on the GNSS receiver and leave it for 5 minutes.
                  </ListItemText>
                  <ListItemText>
                    5. Check for a fix by looking at the green LED on the
                    receiver.
                  </ListItemText>
                </List>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="faq2-content"
                id="faq2-header"
              >
                <Typography variant="subtitle1">
                  Battery won't charge
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <h1>Warm Battery</h1>
                <span>
                  The battery is warm, Charger is plugged in, but the charging
                  LED does not illuminate.
                </span>

                <p>
                  Charging a warm battery is a safety hazard. The Arrow battery
                  has a safety feature that prevents a battery from charging if
                  the battery is too warm. If the battery is warm to the touch
                  and does not begin charging when plugged in, it will start
                  charging when the battery is at a safe temperature.
                </p>
                <p>
                  It is safe to leave the charger plugged in while the battery
                  cools. Charging will resume when the battery is at the
                  appropriate temperature. Keep the battery out of the sun when
                  charging, on a hot day.
                </p>
                <br />
                <h1>Dead Charger</h1>
                <p>Try charging the battery with a different charger.</p>

                <p>
                  If the battery charging indicator illuminates when a different
                  charger is plugged in, there is probably an issue with the
                  charger itself. Check for damage on the cable and replace the
                  charger if it cannot be repaired.
                </p>
                <br />
                <h1>Long Term Storage</h1>
                <span>
                  Like charging a hot battery, charging a battery with an
                  extremely low voltage can be a safety issue. If the battery
                  was not charged before storing for a long time(months), it
                  might be unable to be charged. Please fill out the{" "}
                  <Link to="/rma">RMA request form</Link> and send it in for
                  service.
                </span>

                <p>
                  If the battery charging indicator illuminates when a different
                  charger is plugged in, there is probably an issue with the
                  charger itself. Check for damage on the cable and replace the
                  charger if it cannot be repaired.
                </p>
                <br />
                <h1>Battery End of Life</h1>
                <p>
                  Ultimately, batteries are consumables with a limited life
                  span. After years of use, you can expect the battery to lose
                  some charging capacity. Eventually, it will be unable to hold
                  a charge. At this time, it's time for a replacement. The cost
                  to replace a battery is $295. Reach out to your dealer or fill
                  out the{" "}
                  <a href="https://eos-gnss.com/contact" target="_blank">
                    contact form
                  </a>{" "}
                  to contact someone to purchase a new battery.
                </p>

                <p>
                  If the battery charging indicator illuminates when a different
                  charger is plugged in, there is probably an issue with the
                  charger itself. Check for damage on the cable and replace the
                  charger if it cannot be repaired.
                </p>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="faq3-content"
                id="faq3-header"
              >
                <Typography variant="subtitle1">
                  Can't pair Bluetooth
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <h1>Another Device Connected</h1>
                <p>
                  Check that another device isn't already connected. If the blue
                  LED on the Arrow is solid(not flashing), a device is currently
                  connected to the Arrow. Only one device can be connected at a
                  time.
                </p>

                <p>
                  Once paired, iOS devices will automatically connect to the
                  Arrow each time they are powered on, and Bluetooth is enabled.
                  Android devices do not behave this way. Multiple Android
                  devices can be paired. They won't connect until Eos Tools Pro
                  or another app like Field Maps establishes a connection.
                </p>
                <p>
                  If you are unsure if your device or another device is
                  connected, turn off Bluetooth. The Bluetooth LED on the Arrow
                  will change state to blinking. If it remains solid, another
                  device is connected. Locate that device and turn off Bluetooth
                  or remove the Bluetooth pairing.
                </p>
                <br />
                <h1>Bluetooth Power Switched Off</h1>
                <p>
                  On the bottom of the receiver, underneath the battery, is a
                  small dip switch that controls power to the Bluetooth module.
                  This switch allows the user to turn off Bluetooth to save
                  energy or prevent Bluetooth connections
                </p>

                <p>
                  It is rare for this switch to be in the off position, but it
                  can happen. If this is the case, the Bluetooth LED will be off
                  completely. It will not blink or illuminate at all.
                </p>

                <p>
                  To turn the switch to the on position, use the end of a paper
                  clip, fine-tip pen/pencil, or small screw driver to flip this
                  switch to the on position. Once powered on again, the
                  Bluetooth LED will start blinking, and pairing should be
                  successful.
                </p>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="faq5-content"
                id="faq5-header"
              >
                <Typography variant="subtitle1">
                  How can I request support?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="subtitle1" color="textSecondary">
                  Complete the <Link to="/rma">RMA request form</Link>, then you
                  will receive instructions immediately via email.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </Container>
    </Wrapper>
  );
}

export default Troubleshooting;
