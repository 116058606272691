import React from "react";
import * as Yup from "yup";
import styled from "styled-components/macro";
import { Formik } from "formik";

import {
  Alert as MuiAlert,
  Box,
  Button as MuiButton,
  CircularProgress,
  TextField as MuiTextField,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";

import { InputLabel, MenuItem, FormControl, Select } from "@mui/material";

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Button = styled(MuiButton)(spacing);

const validationSchema = Yup.object().shape({
  product: Yup.string().required("Product type required."),
  serialNumber: Yup.string().required("Serial number required."),
});

function ProductForm(props) {
  const handleSubmit = async (
    values,
    { setErrors, setStatus, setSubmitting }
  ) => {
    try {
      await props.handleSubmit(values);
      setStatus({ sent: true });
      setSubmitting(false);
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={props.values}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        status,
      }) => (
        <div>
          <Typography variant="h6" gutterBottom>
            Product Information
          </Typography>
          <Typography variant="body2" gutterBottom>
            Select the product for RMA
          </Typography>

          {status && status.sent && (
            <Alert severity="success" my={3}>
              [DEMO] Your data has been submitted successfully!
            </Alert>
          )}

          {isSubmitting ? (
            <Box display="flex" justifyContent="center" my={6}>
              <CircularProgress />
            </Box>
          ) : (
            <form onSubmit={handleSubmit}>
              <FormControl m={2} sx={{ width: 1 }}>
                <InputLabel id="product-selection-label">Product</InputLabel>
                <Select
                  name="product"
                  value={values.product}
                  labelId="product-selection-label"
                  id="product"
                  label="Product"
                  onChange={handleChange}
                  my={2}
                >
                  {props.products.map((product) => {
                    return (
                      <MenuItem key={product.pk} value={product.pk}>
                        {product.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <TextField
                name="serialNumber"
                id="serialNumber"
                label="Serial #"
                value={values.serialNumber}
                error={Boolean(touched.product)}
                fullWidth
                onBlur={handleBlur}
                onChange={handleChange}
                variant="outlined"
                my={2}
              />
              <Box
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div></div>
                <Button type="submit" variant="contained" color="primary">
                  Save & Next
                </Button>
              </Box>
            </form>
          )}
        </div>
      )}
    </Formik>
  );
}

export default ProductForm;
