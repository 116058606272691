import React from "react";

import { Typography, Grid } from "@mui/material";

import { CheckCircle } from "@mui/icons-material";
import Features from "../../presentation/Landing/Features";

class Success extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <Typography variant="h1" gutterBottom>
            Submitted
          </Typography>
        </Grid>
        <Grid item>
          <CheckCircle fontSize="large" style={{ color: "green" }} />
        </Grid>
        <Grid item>
          <div style={{ maxWidth: "50em" }}>
            <p>
              Your RMA request has been submitted for review. If we think the
              issues desribed can be solved by setting up a metting a technician
              will reach out to you. If not, the RMA will be accpeted and
              instructions will be sent to the email provided.
            </p>
          </div>
        </Grid>
        <Grid item>
          <Features />
        </Grid>
      </Grid>
    );
  }
}

export default Success;
