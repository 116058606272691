import React from "react";
import styled from "styled-components/macro";

import { useNavigate } from "react-router-dom";

import {
  Box,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Typography,
  CircularProgress,
  Grid,
  Link,
} from "@mui/material";
import { spacing } from "@mui/system";
import { Archive } from "react-feather";
import { Product } from "../tickets/TicketDetails";
import { Contact } from "../tickets/TicketDetails";

const Button = styled(MuiButton)(spacing);

function TicketExists(props) {
  //console.log(props);
  const navigate = useNavigate();

  const openTicket = () => {
    navigate("/ticket/" + props.ticket.id);
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <Grid item>
        <Typography variant="h1" gutterBottom>
          Existing Ticket Found
        </Typography>
      </Grid>
      <Grid item>
        <Archive fontSize="large" style={{ color: "green" }} />
      </Grid>
      <Grid item xs={12} lg={4} xl={3}>
        <Product
          product={props.ticket.product_name}
          productImage="https://eos-gnss.com/wp-content/uploads/2021/06/Arrow-100-GNSS-Receiver-Main-Product-Image.jpg"
          serialNumber={props.ticket.serial_number}
        />
        <Contact
          contactName={props.ticket.contact_name}
          companyName={props.ticket.contact_company}
          contactEmail={props.ticket.contact_email}
          contactPhone={props.ticket.contact_phone}
        />
      </Grid>
      <Grid item>
        <div style={{ maxWidth: "50em" }}>
          <p>{props.error}</p>
        </div>
      </Grid>
      <Grid item>
        <div>
          <Button variant="contained" color="primary" onClick={openTicket}>
            View Ticket
          </Button>
        </div>
      </Grid>
    </Grid>
  );
}

export default TicketExists;
