import React from "react";

import async from "./components/Async";

// All pages that rely on 3rd party components (other than Material-UI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Layouts
import AuthLayout from "./layouts/Auth";
import DefaultLayout from "./layouts/Default";
import DashboardLayout from "./layouts/Dashboard";
import DocLayout from "./layouts/Doc";
import PresentationLayout from "./layouts/Presentation";

// Guards
import AuthGuard from "./components/guards/AuthGuard";

// Auth components
import SignIn from "./pages/auth/SignIn";
import SignUp from "./pages/auth/SignUp";
import ResetPassword from "./pages/auth/ResetPassword";
import Page404 from "./pages/auth/Page404";
import Page500 from "./pages/auth/Page500";

// Components
import Accordion from "./pages/components/Accordion";
import Alerts from "./pages/components/Alerts";
import Avatars from "./pages/components/Avatars";
import Badges from "./pages/components/Badges";
import Buttons from "./pages/components/Buttons";
import Cards from "./pages/components/Cards";
import Chips from "./pages/components/Chips";
import Dialogs from "./pages/components/Dialogs";
import Lists from "./pages/components/Lists";
import Menus from "./pages/components/Menus";
import Pagination from "./pages/components/Pagination";
import Progress from "./pages/components/Progress";
import Snackbars from "./pages/components/Snackbars";
import Tooltips from "./pages/components/Tooltips";

// DMG Pages
import RMARequest from "./pages/RMA/request";
import Status from "./pages/RMA/status/Status";
import TicketLookup from "./pages/RMA/tickets/TicketLookup";
import ProductList from "./pages/RMA/products/list";
import ProductEdit from "./pages/RMA/products/edit";
import ProductAdd from "./pages/RMA/products/add";
import Warranty from "./pages/presentation/Landing/WarrantyInfo";

// Form components
import SelectionCtrls from "./pages/forms/SelectionControls";
import Selects from "./pages/forms/Selects";
import TextFields from "./pages/forms/TextFields";

// Icon components
import MaterialIcons from "./pages/icons/MaterialIcons";

// Page components
import Blank from "./pages/pages/Blank";
import InvoiceDetails from "./pages/pages/InvoiceDetails";
import InvoiceList from "./pages/pages/InvoiceList";
import Orders from "./pages/pages/Orders";
import Pricing from "./pages/pages/Pricing";
import Settings from "./pages/pages/Settings";
import Projects from "./pages/pages/Projects";
import Chat from "./pages/pages/Chat";

// Table components
import SimpleTable from "./pages/tables/SimpleTable";
import AdvancedTable from "./pages/tables/AdvancedTable";

// Documentation
import Welcome from "./pages/docs/Welcome";
import GettingStarted from "./pages/docs/GettingStarted";
import Routing from "./pages/docs/Routing";
import Auth0 from "./pages/docs/auth/Auth0";
import Cognito from "./pages/docs/auth/Cognito";
import Firebase from "./pages/docs/auth/Firebase";
import JWT from "./pages/docs/auth/JWT";
import Guards from "./pages/docs/Guards";
import EnvironmentVariables from "./pages/docs/EnvironmentVariables";
import Deployment from "./pages/docs/Deployment";
import Theming from "./pages/docs/Theming";
import APICalls from "./pages/docs/APICalls";
import Redux from "./pages/docs/Redux";
import Internationalization from "./pages/docs/Internationalization";
import ESLintAndPrettier from "./pages/docs/ESLintAndPrettier";
import MigratingToNextJS from "./pages/docs/MigratingToNextJS";
import Support from "./pages/docs/Support";
import Changelog from "./pages/docs/Changelog";

// Landing
import Landing from "./pages/presentation/Landing";

// Protected routes
import ProtectedPage from "./pages/protected/ProtectedPage";
import TicketList from "./pages/RMA/tickets/TicketList";
import TicketDetail from "./pages/RMA/tickets/TicketDetails";
import Receive from "./pages/RMA/actions/Receive";
import Evaluate from "./pages/RMA/actions/Evaluate";
import Return from "./pages/RMA/actions/Return";
import ApproveTicket from "./pages/RMA/actions/Approve";
import ApproveService from "./pages/RMA/actions/ApproveService";
import CompleteService from "./pages/RMA/actions/CompleteService";
import StandardLayout from "./layouts/Standard";
import IssueList from "./pages/RMA/issues/ListIssues";
import CreateIssue from "./pages/RMA/issues/CreateIssue";
import UpdateIssue from "./pages/RMA/issues/UpdateIssue";
import ManufacturerList from "./pages/RMA/manufacturers/list";
import ManufacturerEdit from "./pages/RMA/manufacturers/edit";
import ManufacturerAdd from "./pages/RMA/manufacturers/add";
import FAQ from "./pages/presentation/Landing/FAQ";
import Troubleshooting from "./pages/presentation/Landing/Troubleshooting";

// Dashboard components
const Default = async(() => import("./pages/dashboards/Default"));
const Analytics = async(() => import("./pages/dashboards/Analytics"));
const SaaS = async(() => import("./pages/dashboards/SaaS"));

// Form components
const Pickers = async(() => import("./pages/forms/Pickers"));
const Editors = async(() => import("./pages/forms/Editors"));
const Formik = async(() => import("./pages/forms/Formik"));

// Icon components
const FeatherIcons = async(() => import("./pages/icons/FeatherIcons"));
const Profile = async(() => import("./pages/pages/Profile"));
const Tasks = async(() => import("./pages/pages/Tasks"));
const Calendar = async(() => import("./pages/pages/Calendar"));

// Table components
const DataGrid = async(() => import("./pages/tables/DataGrid"));

// Chart components
const Chartjs = async(() => import("./pages/charts/Chartjs"));

// Maps components
const GoogleMaps = async(() => import("./pages/maps/GoogleMaps"));
const VectorMaps = async(() => import("./pages/maps/VectorMaps"));

const routes = [
  {
    path: "/",
    element: <StandardLayout />,
    children: [
      {
        path: "",
        element: <Landing />,
      },
    ],
  },
  {
    path: "faq",
    element: <StandardLayout />,
    children: [
      {
        path: "",
        element: <FAQ />,
      },
    ],
  },
  {
    path: "troubleshooting",
    element: <StandardLayout />,
    children: [
      {
        path: "",
        element: <Troubleshooting />,
      },
    ],
  },
  {
    path: "warranty",
    element: <StandardLayout />,
    children: [
      {
        path: "",
        element: <Warranty />,
      },
    ],
  },
  {
    path: "rma",
    element: <StandardLayout />,
    children: [
      {
        path: "",
        element: <RMARequest />,
      },
      {
        path: "request",
        element: <RMARequest />,
      },
    ],
  },
  {
    path: "tickets",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <TicketList />,
      },
      {
        path: "active",
        element: <TicketList status="active" />,
      },
      {
        path: "resolved",
        element: <TicketList status="resolved" />,
      },
    ],
  },
  {
    path: "ticket",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <TicketList />,
      },
      {
        path: "create",
        element: <RMARequest />,
      },
      {
        path: "lookup",
        element: <TicketLookup />,
      },
      {
        path: ":id",
        element: <TicketDetail />,
      },
      {
        path: ":id/approve",
        element: <ApproveTicket />,
      },
      {
        path: ":id/receive",
        element: <Receive />,
      },
      {
        path: ":id/evaluate",
        element: <Evaluate />,
      },
      {
        path: ":id/completeservice",
        element: <CompleteService />,
      },
      {
        path: ":id/return",
        element: <Return />,
      },
    ],
  },
  {
    path: "ticket/:id/approveservice",
    element: <ApproveService />,
  },
  {
    path: "issues",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <IssueList />,
      },
      {
        path: ":id",
        element: <UpdateIssue />,
      },
      {
        path: "new",
        element: <CreateIssue />,
      },
    ],
  },
  {
    path: "products",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <ProductList />,
      },
    ],
  },
  {
    path: "product",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <ProductList />,
      },
      {
        path: ":id",
        element: <ProductEdit />,
      },
      {
        path: "add",
        element: <ProductAdd />,
      },
    ],
  },
  {
    path: "manufacturers",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <ManufacturerList />,
      },
    ],
  },
  {
    path: "manufacturer",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <ManufacturerList />,
      },
      {
        path: ":id",
        element: <ManufacturerEdit />,
      },
      {
        path: "add",
        element: <ManufacturerAdd />,
      },
    ],
  },

  {
    path: "auth",
    element: <AuthLayout />,
    children: [
      {
        path: "sign-in",
        element: <SignIn />,
      },
      {
        path: "sign-up",
        element: <SignUp />,
      },
      {
        path: "reset-password",
        element: <ResetPassword />,
      },
      {
        path: "404",
        element: <Page404 />,
      },
      {
        path: "500",
        element: <Page500 />,
      },
    ],
  },
  {
    path: "*",
    element: <AuthLayout />,
    children: [
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
];

export default routes;
