import React from "react";
import styled from "styled-components/macro";

import {
  Box,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Container,
  Grid,
  Typography,
  CircularProgress,
} from "@mui/material";
import { spacing } from "@mui/system";

// Forms
import ProductForm from "./ProductForm.js";
import ContactForm from "./ContactForm.js";
import ReturnAddressForm from "./ReturnAddressForm.js";
import RMADescriptionForm from "./DescriptionForm.js";
import Review from "./Review.js";
import Success from "./Success.js";
import TicketExists from "./TicektExists.js";
import { apiurl } from "../../../config.js";

const axios = require("axios").default;

const Card = styled(MuiCard)(spacing);

const Button = styled(MuiButton)(spacing);

const Wrapper = styled.div`
  padding-top: 3.5rem;
  position: relative;
  text-align: center;
  overflow: hidden;
`;

class RMARequest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      step: 1,
      products: [],
      productInfo: {
        product: "",
        serialNumber: "",
      },
      contactInfo: {
        contactName: "",
        companyName: "",
        contactPhone: "",
        contactEmail: "",
      },
      returnInfo: {
        returnName: "",
        returnPhone: "",
        returnAddress: "",
        returnAddress2: "",
        returnCity: "",
        returnState: "",
        returnZip: "",
      },
      description: "",
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.nextStep = this.nextStep.bind(this);
    this.previousStep = this.previousStep.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.showComponent = this.showComponent.bind(this);
    this.handleProductInfo = this.handleProductInfo.bind(this);
    this.handleContactInfo = this.handleContactInfo.bind(this);
    this.handleReturnInfo = this.handleReturnInfo.bind(this);
    this.updateRMADescription = this.updateRMADescription.bind(this);
    this.handleRMADescription = this.handleRMADescription.bind(this);
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    axios
      .get(apiurl + "/products/")
      .then((response) => {
        this.setState({ products: response.data, isLoading: false });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  nextStep() {
    this.setState({
      step: this.state.step + 1,
    });
  }

  previousStep() {
    var previousStep = this.state.step - 1;
    if (previousStep < 1) previousStep = 1;
    this.setState({
      step: previousStep,
    });
  }

  handleProductInfo(data) {
    this.setState({ productInfo: data }, function () {
      this.nextStep();
    });
  }

  handleContactInfo(data) {
    var updatedReturnInfo = { ...this.state.returnInfo };
    updatedReturnInfo.returnName = data.contactName;
    updatedReturnInfo.returnPhone = data.contactPhone;
    this.setState(
      {
        contactInfo: data,
        returnInfo: updatedReturnInfo,
      },
      function () {
        this.nextStep();
      }
    );
  }

  handleReturnInfo(data) {
    this.setState({ returnInfo: data }, function () {
      this.nextStep();
    });
  }

  updateRMADescription(data) {
    this.setState({ description: data });
  }

  handleRMADescription(data) {
    this.setState({ description: data }, function () {
      this.nextStep();
    });
  }

  handleSubmit() {
    this.setState({ isLoading: true });

    axios
      .post(apiurl + "/tickets/new/", {
        product: apiurl + "/products/" + this.state.productInfo.product + "/",
        serial_number: this.state.productInfo.serialNumber,
        description: this.state.description,
        contact_company: this.state.contactInfo.companyName,
        contact_name: this.state.contactInfo.contactName,
        contact_email: this.state.contactInfo.contactEmail,
        contact_phone: this.state.contactInfo.contactPhone,
        return_attention: this.state.returnInfo.returnName,
        return_address: this.state.returnInfo.returnAddress,
        return_city: this.state.returnInfo.returnCity,
        return_state: this.state.returnInfo.returnState,
        return_zip: this.state.returnInfo.returnZip,
      })
      .then((response) => {
        this.setState({ isLoading: false });
        this.nextStep();
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        console.log(error);
        this.setState(
          {
            response: error.response.data,
          },
          () => {
            this.setState({
              step: 7,
            });
          }
        );
      });
  }

  FormButtons() {
    if (this.state.step > 1) {
      return (
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={this.previousStep}
          >
            Back
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={this.nextStep}
          >
            Next
          </Button>
        </Box>
      );
    } else {
      return (
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div></div>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={this.nextStep}
          >
            Next
          </Button>
        </Box>
      );
    }
  }

  submitForm() {
    return true;
  }

  showComponent() {
    let component;
    switch (this.state.step) {
      case 1:
        component = (
          <ProductForm
            handleSubmit={this.handleProductInfo}
            values={this.state.productInfo}
            products={this.state.products}
          />
        );
        break;
      case 2:
        component = (
          <ContactForm
            handleSubmit={this.handleContactInfo}
            values={this.state.contactInfo}
            previousStep={this.previousStep}
          />
        );
        break;
      case 3:
        component = (
          <ReturnAddressForm
            handleSubmit={this.handleReturnInfo}
            values={this.state.returnInfo}
            previousStep={this.previousStep}
          />
        );
        break;
      case 4:
        component = (
          <RMADescriptionForm
            handleSubmit={this.handleRMADescription}
            handleUpdate={this.updateRMADescription}
            description={this.state.description}
            previousStep={this.previousStep}
          />
        );
        break;
      case 5:
        component = (
          <Review
            data={this.state}
            handleSubmit={this.handleSubmit}
            previousStep={this.previousStep}
          />
        );
        break;
      case 6:
        component = <Success />;
        break;
      case 7:
        component = (
          <TicketExists
            ticket={this.state.response.ticket}
            message={this.state.response.error}
          />
        );
        break;
      default:
        component = <div></div>;
    }
    return component;
  }

  render() {
    return (
      <Wrapper>
        <Container>
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            spacing={4}
          >
            <Grid item xs={12} sm={9} md={8} lg={8}>
              <Card mb={6}>
                <CardContent>
                  <Typography variant="h1" gutterBottom>
                    RMA Request
                  </Typography>
                  {this.state.isLoading ? (
                    <Box display="flex" justifyContent="center" my={6}>
                      <CircularProgress />
                    </Box>
                  ) : (
                    this.showComponent()
                  )}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Wrapper>
    );
  }
}

export default RMARequest;
