import * as React from "react";
import styled from "styled-components/macro";
import { spacing } from "@mui/system";
import { Link } from "react-router-dom";

import {
  AppBar,
  Button as MuiButton,
  Container,
  Grid,
  Box,
  Toolbar,
} from "@mui/material";

import companyLogo from "../../../vendor/dmg-logo.png";
import { $CombinedState } from "redux";

function DMGLogo(props) {
  return (
    <img
      src={companyLogo}
      alt="DMG logo"
      style={{
        marginRight: "5px",
        marginTop: "-2px",
        width: "150px",
        verticalAlign: "middle",
        display: "inline",
      }}
    />
  );
}

const Button = styled(MuiButton)(spacing);

const Brand = styled.div`
  font-size: ${(props) => props.theme.typography.h5.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  font-family: ${(props) => props.theme.typography.fontFamily};
`;

const AppBarComponent = () => (
  <React.Fragment>
    <AppBar position="relative" color="transparent" elevation={0}>
      <Toolbar>
        <Container>
          <Grid container alignItems="center">
            <Grid item>
              <Brand>
                <Link to="/">
                  <DMGLogo />
                </Link>
              </Brand>
            </Grid>
            <Grid item xs />
            <Grid item>
              <Box sx={{ display: { xs: "none", md: "inline-block" } }}>
                <Button
                  ml={2}
                  color="inherit"
                  component={Link}
                  to="/troubleshooting"
                >
                  Troubleshooting
                </Button>
                <Button ml={2} color="inherit" component={Link} to="/faq">
                  FAQ
                </Button>
                <Button ml={2} color="inherit" component={Link} to="/warranty">
                  Warranty Info
                </Button>
              </Box>
              <Button
                ml={2}
                color="primary"
                variant="contained"
                component={Link}
                to="/rma"
              >
                Request RMA
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Toolbar>
    </AppBar>
  </React.Fragment>
);

export default AppBarComponent;
