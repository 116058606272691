import React from "react";
import { Grid, Step, StepLabel, Stepper } from "@mui/material";

const steps = [
  "RMA Requested",
  "RMA Approved",
  "Equipment Received",
  "Evaluation Complete",
  "Service Approved",
  "Service Complete",
  "Equipment Returned",
];

export function Progress(props) {
  return (
    <React.Fragment>
      <Grid container spacing={6} mt={4} mb={8} justifyContent="center">
        <Stepper activeStep={props.step} alternativeLabel>
          {steps.map((label) => (
            <Step
              key={label}
              sx={{
                "& .MuiStepLabel-root .Mui-completed": {
                  color: "green", // circle color (COMPLETED)
                },
                "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel":
                  {
                    color: "gray", // Just text label (COMPLETED)
                  },
                "& .MuiStepLabel-root .Mui-active": {
                  color: "secondary.main", // circle color (ACTIVE)
                },
                "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel":
                  {
                    color: "common.white", // Just text label (ACTIVE)
                  },
                "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
                  fill: "common.white", // circle's number (ACTIVE)
                },
                "& .Mui-disabled .MuiStepIcon-root": {
                  color: "gray", // circle's number (ACTIVE)
                },
              }}
            >
              <StepLabel>
                <span style={{ color: "black" }}>{label}</span>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Grid>
    </React.Fragment>
  );
}
export default Progress;
