import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { useParams } from "react-router-dom";
import {
  Box,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  TextField as MuiTextField,
  Typography,
  CircularProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { spacing } from "@mui/system";

import { useNavigate } from "react-router-dom";

import { InputLabel, MenuItem, FormControl, Select } from "@mui/material";
import { apiurl } from "../../../config.js";

const axios = require("axios").default;

const Card = styled(MuiCard)(spacing);

const Button = styled(MuiButton)(spacing);

const TextField = styled(MuiTextField)(spacing);

function ProductEdit() {
  const [isLoading, setLoading] = React.useState(true);
  const [manufacturers, setManufacturers] = useState([]);
  const [name, setName] = useState("");
  const [manufacturer, setManufacturer] = useState();
  const [image, setImage] = useState("");
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProduct = axios.get(apiurl + "/products/" + id);
    const fetchManufacturers = axios.get(apiurl + "/manufacturers/");

    axios
      .all([fetchProduct, fetchManufacturers])
      .then(
        axios.spread((...responses) => {
          const product = responses[0].data;
          const fetchedManufacturers = responses[1].data;

          setName(product.name);
          setImage(product.image);
          setManufacturers(fetchedManufacturers);

          axios
            .get(product.manufacturer)
            .then((response) => {
              setManufacturer(response.data.pk);
              setLoading(false);
            })
            .catch((errors) => {
              console.log(errors);
            });
        })
      )
      .catch((errors) => {
        console.log(errors);
      });
  }, []);

  function handleSubmit() {
    setLoading(true);
    console.log("Submitting form to API...");

    axios
      .patch(apiurl + "/products/" + id + "/", {
        name: name,
        manufacturer: apiurl + "/manufacturers/" + manufacturer + "/",
      })
      .then((response) => {
        setLoading(false);
        console.log("Product successfully updated.");
        navigate("/products");
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }

  const handleChange = (event) => {
    setManufacturer(event.target.value);
  };

  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h1" gutterBottom>
          Edit Product
        </Typography>
        {isLoading ? (
          <Box display="flex" justifyContent="center" my={6}>
            <CircularProgress />
          </Box>
        ) : (
          <form onSubmit={handleSubmit}>
            <img src={image} width="150em" />
            <TextField
              autoFocus
              name="name"
              id="name"
              label="Name"
              value={name}
              error={!name}
              fullWidth
              onChange={(e) => {
                setName(e.target.value);
              }}
              variant="outlined"
              my={2}
            />
            <FormControl fullWidth>
              <InputLabel id="manufacturer-label">Manufacturer</InputLabel>
              <Select
                labelId="manufacturer-label"
                id="manufacturer"
                value={manufacturer}
                label="Manufacturer"
                onChange={handleChange}
              >
                {manufacturers.map((manufacturer) => {
                  return (
                    <MenuItem key={manufacturer.pk} value={manufacturer.pk}>
                      {manufacturer.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <br />
            <br />
            <Box
              style={{
                display: "flex",
                justifyContent: "right",
              }}
            >
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={!name || !manufacturer}
              >
                Save
              </Button>
            </Box>
          </form>
        )}
      </CardContent>
    </Card>
  );
}

export default ProductEdit;
