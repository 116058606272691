import React, { useState } from "react";
import styled from "styled-components/macro";

import {
  Alert as MuiAlert,
  Box,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  TextField as MuiTextField,
  Typography,
  CircularProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { spacing } from "@mui/system";

import { useNavigate } from "react-router-dom";
import { apiurl } from "../../../config.js";

const axios = require("axios").default;

const Card = styled(MuiCard)(spacing);

const Button = styled(MuiButton)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Alert = styled(MuiAlert)(spacing);

function CreateIssue(props) {
  const [isLoading, setLoading] = React.useState(false);
  const [description, setDescription] = useState("");
  const [resolution, setResolution] = useState("");
  const [error, setError] = useState("");

  const navigate = useNavigate();

  function handleSubmit() {
    setLoading(true);
    axios
      .post(apiurl + "/issues/", {
        description: description,
        resolution: resolution,
      })
      .then((response) => {
        navigate("/issues");
      })
      .catch((error) => {
        setLoading(false);
        setError(error.response.data.description);
      });
  }

  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h1" gutterBottom>
          Create Issue
        </Typography>
        {isLoading ? (
          <Box display="flex" justifyContent="center" my={6}>
            <CircularProgress />
          </Box>
        ) : (
          <form onSubmit={handleSubmit}>
            <Typography variant="body2" gutterBottom>
              Clearly and briefly describe the issue and resoluion. Issues are
              meant to be reused across many models and manufactures.
            </Typography>
            {error && (
              <Alert mb={4} variant="outlined" severity="error">
                {error}
              </Alert>
            )}
            <TextField
              autoFocus
              name="description"
              id="description"
              label="Description"
              value={description}
              error={!description}
              fullWidth
              onChange={(e) => {
                setDescription(e.target.value);
              }}
              variant="outlined"
              my={2}
            />
            <TextField
              name="resolution"
              id="resolution"
              label="Resolution"
              value={resolution}
              error={!resolution}
              fullWidth
              onChange={(e) => {
                setResolution(e.target.value);
              }}
              variant="outlined"
              my={2}
            />
            <Box
              style={{
                display: "flex",
                justifyContent: "right",
              }}
            >
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={!description || !resolution}
              >
                Add
              </Button>
            </Box>
          </form>
        )}
      </CardContent>
    </Card>
  );
}

export default CreateIssue;
