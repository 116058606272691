import { React, useState } from "react";
import styled from "styled-components/macro";

// Quill imports
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";

import { Box, Typography, Button as MuiButton } from "@mui/material";

import { spacing } from "@mui/system";

const Button = styled(MuiButton)(spacing);

const QuillWrapper = styled.div`
  .ql-editor {
    min-height: 200px;
  }
`;

function RMADescriptionForm(props) {
  const [value, setValue] = useState(props.description);

  return (
    <div>
      <Typography variant="h6" gutterBottom>
        Reason for RMA
      </Typography>
      <Typography variant="body2" gutterBottom>
        Describe in as much detail as possible the issue experienced with the
        equipment and any troubleshooting that has been done.
      </Typography>
      <Box mt={3}>
        <QuillWrapper>
          <ReactQuill
            theme="snow"
            fullWidth
            value={value}
            onChange={setValue}
            placeholder="Type something.."
          />
        </QuillWrapper>
      </Box>
      <br />
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={props.previousStep}
        >
          Back
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          onClick={() => props.handleSubmit(value)}
        >
          Save & Review
        </Button>
      </Box>
    </div>
  );
}

export default RMADescriptionForm;
