import React from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";

import { Button, Box, Container, Typography } from "@mui/material";
import { spacing } from "@mui/system";

import { TextField } from "@mui/material";
import { Search as SearchIcon } from "react-feather";

const Wrapper = styled.div`
  ${spacing};
  text-align: center;
`;

const TypographyOverline = styled(Typography)`
  text-transform: uppercase;
  color: ${(props) => props.theme.palette.primary.main};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
`;

function Integrations() {
  return (
    <Wrapper py={4}>
      <Container>
        <TypographyOverline variant="body2" gutterBottom>
          Already requested an RMA?
        </TypographyOverline>
        <Typography variant="h2" component="h3" gutterBottom>
          Check Service Status
        </Typography>
        <Box my={1}>
          <TextField
            m={5}
            required
            id="serial-number"
            label="Serial Number"
            placeholder="xxxx-xxxxxxxx"
          />
        </Box>
        <br />
        <Box>
          <Button
            component={NavLink}
            to="/documentation/welcome"
            variant="contained"
            color="secondary"
            size="large"
            target="_blank"
          >
            <SearchIcon />
            <span>&nbsp;</span>
            Check Status
          </Button>
        </Box>
      </Container>
    </Wrapper>
  );
}

export default Integrations;
