import React from "react";
import styled from "styled-components/macro";

import {
  Box,
  Button as MuiButton,
  TextField as MuiTextField,
  Typography,
  Grid,
} from "@mui/material";
import { spacing } from "@mui/system";

// Quill imports
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";

const QuillWrapper = styled.div`
  .ql-editor {
    min-height: 200px;
  }
`;

const Button = styled(MuiButton)(spacing);

const TextField = styled(MuiTextField)(spacing);

function Review(props) {
  return (
    <div>
      <Grid container spacing={6}>
        <Grid item md={6}>
          <Typography variant="h6" gutterBottom>
            Product
          </Typography>
          <TextField
            name="product"
            id="product"
            value={
              props.data.products.find(
                (x) => x.pk === props.data.productInfo.product
              ).name
            }
            readOnly
            fullWidth
            variant="outlined"
            my={2}
          />
        </Grid>
        <Grid item md={6}>
          <Typography variant="h6" gutterBottom>
            Serial #
          </Typography>
          <TextField
            name="serialNumber"
            id="serialNumber"
            readOnly
            value={props.data.productInfo.serialNumber}
            fullWidth
            variant="outlined"
            my={2}
          />
        </Grid>
      </Grid>
      <Grid container spacing={6}>
        <Grid item md={6}>
          <Typography variant="h6" gutterBottom>
            Contact
          </Typography>
          <QuillWrapper>
            <ReactQuill
              toolbar
              readOnly
              theme="snow"
              modules={{ toolbar: false }}
              fullWidth
              value={
                "<span>Name: " +
                props.data.contactInfo.contactName +
                "</span><br />" +
                "<span>Company: " +
                props.data.contactInfo.companyName +
                "</span><br />" +
                "<span>Email: " +
                props.data.contactInfo.contactEmail +
                "</span><br />" +
                "<span>Phone: " +
                props.data.contactInfo.contactPhone +
                "</span><br />"
              }
              placeholder="Loading.."
            />
          </QuillWrapper>
        </Grid>
        <Grid item md={6}>
          <Typography variant="h6" gutterBottom>
            Return Address
          </Typography>
          <QuillWrapper>
            <ReactQuill
              toolbar
              readOnly
              theme="snow"
              modules={{ toolbar: false }}
              fullWidth
              value={
                "<span>Attn: " +
                props.data.returnInfo.returnName +
                "</span><br />" +
                "<span>" +
                props.data.returnInfo.returnAddress +
                "</span><br />" +
                "<span>" +
                props.data.returnInfo.returnAddress2 +
                "</span><br />" +
                "<span> " +
                props.data.returnInfo.returnCity +
                ", " +
                props.data.returnInfo.returnState +
                " " +
                props.data.returnInfo.returnZip +
                "</span><br />" +
                "<span>" +
                props.data.returnInfo.returnPhone +
                "</span><br />"
              }
              placeholder="Loading.."
            />
          </QuillWrapper>
        </Grid>
      </Grid>
      <br />
      <Typography variant="h6" gutterBottom>
        Reason for RMA
      </Typography>
      <QuillWrapper>
        <ReactQuill
          toolbar
          readOnly
          theme="snow"
          modules={{ toolbar: false }}
          fullWidth
          value={props.data.description}
          placeholder="Loading.."
        />
      </QuillWrapper>
      <br />
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={props.previousStep}
        >
          Back
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          onClick={props.handleSubmit}
        >
          Submit
        </Button>
      </Box>
    </div>
  );
}

export default Review;
