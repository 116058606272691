import React from "react";
import * as Yup from "yup";
import styled from "styled-components/macro";
import { Formik } from "formik";

import {
  Alert as MuiAlert,
  Box,
  Button as MuiButton,
  CircularProgress,
  Grid,
  TextField as MuiTextField,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import MuiPhoneNumber from "material-ui-phone-number";

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

const MuiPhone = styled(MuiPhoneNumber)(spacing);

const Button = styled(MuiButton)(spacing);

const validationSchema = Yup.object().shape({
  returnName: Yup.string().required("Required"),
  returnPhone: Yup.string().required("Required"),
  returnAddress: Yup.string().required("Required"),
  returnCity: Yup.string().required("Required"),
  returnState: Yup.string().required("Required"),
  returnZip: Yup.string().required("Required"),
});

function ReturnAddressForm(props) {
  const handleSubmit = async (
    values,
    { setErrors, setStatus, setSubmitting }
  ) => {
    try {
      await props.handleSubmit(values);
      setStatus({ sent: true });
      setSubmitting(false);
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={props.values}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        status,
      }) => (
        <div>
          <Typography variant="h6" gutterBottom>
            Return Address
          </Typography>
          <Typography variant="body2" gutterBottom>
            Address to send equipment after service.
          </Typography>

          {status && status.sent && (
            <Alert severity="success" my={3}>
              [DEMO] Your data has been submitted successfully!
            </Alert>
          )}

          {isSubmitting ? (
            <Box display="flex" justifyContent="center" my={6}>
              <CircularProgress />
            </Box>
          ) : (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={6}>
                <Grid item md={6}>
                  <TextField
                    name="returnName"
                    label="Contact Name"
                    value={values.returnName}
                    error={Boolean(touched.returnName && errors.returnName)}
                    fullWidth
                    helperText={touched.returnName && errors.returnName}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={2}
                  />
                </Grid>
                <Grid item md={6}>
                  <MuiPhone
                    name="returnPhone"
                    label="Phone Number"
                    value={values.returnPhone}
                    data-cy="return-phone"
                    defaultCountry={"us"}
                    onChange={handleChange("returnPhone")}
                    onBlur={handleBlur}
                    helperText={touched.returnPhone && errors.returnPhone}
                    error={Boolean(touched.returnPhone && errors.returnPhone)}
                    fullWidth
                    type="tel"
                    variant="outlined"
                    my={2}
                  />
                </Grid>
              </Grid>
              <Grid item>
                <TextField
                  name="returnAddress"
                  label="Address"
                  value={values.returnAddress}
                  error={Boolean(touched.returnAddress && errors.returnAddress)}
                  fullWidth
                  helperText={touched.returnAddress && errors.returnAddress}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  variant="outlined"
                  my={2}
                />
              </Grid>
              <Grid container spacing={6}>
                <Grid item md={6}>
                  <TextField
                    name="returnCity"
                    label="City"
                    value={values.returnCity}
                    error={Boolean(touched.returnCity && errors.returnCity)}
                    fullWidth
                    helperText={touched.returnCity && errors.returnCity}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={2}
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    name="returnState"
                    label="State"
                    value={values.returnState}
                    error={Boolean(touched.returnState && errors.returnState)}
                    fullWidth
                    helperText={touched.returnState && errors.returnState}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="state"
                    variant="outlined"
                    inputProps={{ maxLength: 2 }}
                    my={2}
                  />
                </Grid>
              </Grid>
              <Grid item md={6}>
                <TextField
                  name="returnZip"
                  label="Zip Code"
                  value={values.returnZip}
                  error={Boolean(touched.returnZip && errors.returnZip)}
                  fullWidth
                  helperText={touched.returnZip && errors.returnZip}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  inputProps={{ min: 0, max: 99999 }}
                  type="number"
                  variant="outlined"
                  my={2}
                />
              </Grid>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={props.previousStep}
                >
                  Back
                </Button>
                <Button type="submit" variant="contained" color="primary">
                  Save & Next
                </Button>
              </Box>
            </form>
          )}
        </div>
      )}
    </Formik>
  );
}

export default ReturnAddressForm;
