import React, { useState, useEffect } from "react";
import { Navigate, useParams } from "react-router-dom";
import styled from "styled-components/macro";

import {
  Box,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  TextField as MuiTextField,
  Typography,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  InputAdornment,
  Autocomplete,
  ImageList,
  ImageListItem,
} from "@mui/material";
import { spacing } from "@mui/system";

// Quill imports
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";

import { Camera, Image, CameraOff, AlertCircle, Trash2 } from "react-feather";
import { useNavigate } from "react-router-dom";

const axios = require("axios").default;

const Card = styled(MuiCard)(spacing);

const Button = styled(MuiButton)(spacing);

const TextField = styled(MuiTextField)(spacing);

const QuillWrapper = styled.div`
  .ql-editor {
    min-height: 200px;
  }
`;

function EvaluationDetail(props) {
  const [isLoading, setLoading] = React.useState(false);
  const [evaluation, setEvaluation] = useState({});
  const [issues, setIssues] = useState([]);

  useEffect(() => {
    if (props.ticket.evaluation) {
      setLoading(true);
      axios
        .get(props.ticket.evaluation)
        .then((response) => {
          setEvaluation(response.data);
          return Promise.allSettled(
            response.data.issues.map((endpoint) => axios.get(endpoint))
          );
        })
        .then(
          axios.spread((...results) => {
            setIssues(results.map((issue) => issue.value.data));
          })
        )
        .then(() => {
          setLoading(false);
        });
    }
  }, []);

  if (props.ticket.evaluation) {
    return (
      <Card mb={6}>
        <CardContent>
          {isLoading ? (
            <Box display="flex" justifyContent="center" my={6}>
              <CircularProgress />
            </Box>
          ) : (
            <React.Fragment>
              <Summary summary={evaluation.summary} />
              <br />
              <Typography variant="h6" gutterBottom>
                Issues
              </Typography>
              <Issues issues={issues} />
              <br />
              <Typography variant="h6" gutterBottom>
                Photos
              </Typography>
              <EvaluationImageList images={evaluation.images} />
              <br />
              <TextField
                name="estimate"
                id="estimate"
                label="Estimate #"
                value={evaluation.estimate_num}
                fullWidth
                inputProps={{ readOnly: true }}
                variant="outlined"
                my={2}
              />
              <TextField
                name="cost"
                id="cost"
                label="Cost"
                value={evaluation.total_cost}
                fullWidth
                inputProps={{ readOnly: true }}
                variant="outlined"
                my={2}
              />
              <br />
            </React.Fragment>
          )}
        </CardContent>
      </Card>
    );
  } else {
    return <React.Fragment>Equipment has not been evaluated.</React.Fragment>;
  }
}

export default EvaluationDetail;

function Summary(props) {
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Summary
      </Typography>
      <Box mt={3}>
        <QuillWrapper>
          <ReactQuill
            theme="snow"
            fullWidth
            readOnly={true}
            modules={{ toolbar: false }}
            value={props.summary}
            placeholder="No summary."
          />
        </QuillWrapper>
      </Box>
    </React.Fragment>
  );
}

function Issues({ issues }) {
  const [theIssues, setIssues] = useState(issues);
  useEffect(() => {
    setIssues(issues);
  }, [issues]);
  console.log("|| loading issues: ", issues);

  return theIssues ? populatedIssues() : emptyIssues();

  function emptyIssues() {
    console.log("|| calling empty");
    return (
      <React.Fragment>
        <List dense={true}>
          <ListItem key={0}>
            <ListItemIcon>
              <AlertCircle />
            </ListItemIcon>
            <ListItemText primary="No issues" />
          </ListItem>
        </List>
      </React.Fragment>
    );
  }

  function populatedIssues() {
    console.log("|| calling populated", theIssues);
    return (
      <React.Fragment>
        <List dense={true}>
          {theIssues.map((issue, index) => (
            <ListItem key={index}>
              <ListItemIcon>
                <AlertCircle />
              </ListItemIcon>
              <ListItemText
                primary={issue.description + " - " + issue.resolution}
              />
            </ListItem>
          ))}
        </List>
      </React.Fragment>
    );
  }
}

function EvaluationImageList(props) {
  if (!props.images) {
    return (
      <div
        style={{
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          verticalAlign: "middle",
        }}
      >
        <Image />
        <span>No images.</span>
      </div>
    );
  } else {
    return (
      <div>
        <ImageList sx={{ width: 500 }} cols={3} rowHeight={164}>
          {props.images.map((image, index) => (
            <a href={image} target="_blank" rel="noreferrer">
              <ImageListItem key={index}>
                <img
                  src={image}
                  srcSet={image}
                  alt={"evaluation-" + index}
                  loading="lazy"
                />
              </ImageListItem>
            </a>
          ))}
        </ImageList>
      </div>
    );
  }
}
