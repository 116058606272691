import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components/macro";

import {
  Box,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  TextField as MuiTextField,
  Typography,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  InputAdornment,
} from "@mui/material";
import { spacing } from "@mui/system";

import { Camera, Image } from "react-feather";

import { useNavigate } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";
import { apiurl } from "../../../config.js";

const axios = require("axios").default;

const Card = styled(MuiCard)(spacing);

const Button = styled(MuiButton)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Centered = styled.div`
  text-align: center;
`;

function ApproveTicket(props) {
  const [isLoading, setLoading] = React.useState(false);
  const { id } = useParams();

  const navigate = useNavigate();
  const { user } = useAuth();

  function handleApprove() {
    setLoading(true);
    axios
      .patch(apiurl + "/tickets/" + id + "/", {
        rma_approved_by: user.first_name + " " + user.last_name,
      })
      .then((response) => {
        navigate("/ticket/" + id);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }

  function handleResolve() {
    setLoading(true);
    axios
      .patch(apiurl + "/tickets/" + id + "/", {})
      .then((response) => {
        console.log(response);
        navigate("/ticket/" + id);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }

  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h1" gutterBottom>
          Approve Ticket
        </Typography>
        {isLoading ? (
          <Box display="flex" justifyContent="center" my={6}>
            <CircularProgress />
          </Box>
        ) : (
          <React.Fragment>
            <Typography variant="body2" gutterBottom>
              Can this issue be resolved via phone, email, or virtual meeting?
              If so, click the resolve button. If not, click the Approve button.
            </Typography>
            <Centered>
              <Typography variant="body2" gutterBottom>
                Updating ticket as{" "}
                <b>{user && user.first_name + " " + user.last_name}.</b>
              </Typography>
            </Centered>
            <br />
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                type="submit"
                variant="contained"
                color="primary"
                onClick={handleResolve}
              >
                Resolve
              </Button>
              &nbsp;
              <Button
                type="submit"
                variant="contained"
                color="primary"
                onClick={handleApprove}
              >
                Approve
              </Button>
            </Box>
          </React.Fragment>
        )}
      </CardContent>
    </Card>
  );
}

export default ApproveTicket;
