import React from "react";

import Introduction from "./Introduction";
import Integrations from "./Integrations";
import Features from "./Features";
import Troubleshooting from "./Troubleshooting";
import FAQ from "./FAQ";

function Landing() {
  return (
    <React.Fragment>
      <Introduction />
      <Troubleshooting />
      <Features />
      <FAQ />
    </React.Fragment>
  );
}

export default Landing;
