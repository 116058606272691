import React, { useEffect } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";

import {
  Button,
  Divider as MuiDivider,
  Grid,
  Paper as MuiPaper,
  Typography,
  CircularProgress,
} from "@mui/material";
import { Add as AddIcon } from "@mui/icons-material";
import { spacing } from "@mui/system";

import { useNavigate } from "react-router-dom";
import { apiurl } from "../../../config.js";
import TicketTable from "../../../components/TicketTable.js";

const Divider = styled(MuiDivider)(spacing);

const Paper = styled(MuiPaper)(spacing);

const axios = require("axios").default;

function TicketList(props) {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [tickets, setTickets] = React.useState([]);

  useEffect(() => {
    setLoading(true);
    setTickets([]);
    var url = apiurl + "/tickets/";
    if (props.status) {
      url = apiurl + "/tickets/?status=" + props.status;
    }
    axios
      .get(url)
      .then((response) => {
        for (let i = 0; i < response.data.length; i++) {
          setTickets((tickets) => [...tickets, response.data[i]]);
        }
      })
      .then(() => {
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [props.status, localStorage.getItem("authenticated")]);

  if (loading) {
    return (
      <div>
        <Paper>
          <CircularProgress />
        </Paper>
      </div>
    );
  } else {
    return (
      <React.Fragment>
        <Helmet title="Tickets" />

        <Grid justifyContent="space-between" container spacing={10}>
          <Grid item>
            <Typography variant="h3" gutterBottom display="inline">
              Tickets
            </Typography>
          </Grid>
          <Grid item>
            <div>
              <Button
                variant="contained"
                color="primary"
                onClick={() => navigate("/ticket/create")}
              >
                <AddIcon />
                Create Ticket
              </Button>
            </div>
          </Grid>
        </Grid>

        <Divider my={6} />

        <Grid container spacing={6}>
          <Grid item xs={12}>
            <TicketTable tickets={tickets} />
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

export default TicketList;
