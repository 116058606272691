import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components/macro";

import {
  Box,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  TextField as MuiTextField,
  Typography,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  InputAdornment,
} from "@mui/material";
import { spacing } from "@mui/system";

import { useNavigate } from "react-router-dom";

import useAuth from "../../../hooks/useAuth";
import { apiurl } from "../../../config.js";

const axios = require("axios").default;

const Card = styled(MuiCard)(spacing);

const Button = styled(MuiButton)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Centered = styled.div`
  text-align: center;
`;

function CompleteService(props) {
  const [isLoading, setLoading] = React.useState(false);
  const [invoice, setInvoice] = React.useState("");
  const { id } = useParams();

  const navigate = useNavigate();
  const { user } = useAuth();

  function handleApprove() {
    setLoading(true);
    axios
      .patch(apiurl + "/tickets/" + id + "/", {
        service_completed_by: user.first_name + " " + user.last_name,
        invoice: invoice,
      })
      .then((response) => {
        navigate("/ticket/" + id);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }

  function handleCancel() {
    setLoading(true);
    axios
      .patch(apiurl + "/tickets/" + id + "/", { status: 7 })
      .then((response) => {
        navigate("/ticket/" + id);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }

  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h1" gutterBottom>
          Complete Service
        </Typography>
        {isLoading ? (
          <Box display="flex" justifyContent="center" my={6}>
            <CircularProgress />
          </Box>
        ) : (
          <React.Fragment>
            <Centered>
              <TextField
                autoFocus
                name="invoice"
                id="invoice"
                label="Invoice"
                value={invoice}
                error={!invoice}
                style={{ width: 500 }} //assign the width as your requirement
                onChange={(e) => {
                  setInvoice(e.target.value);
                }}
                variant="outlined"
                my={2}
              />
              <Typography variant="body2" gutterBottom>
                Sign off as service completed by{" "}
                <b>{user && user.first_name + " " + user.last_name}</b>?
              </Typography>
            </Centered>
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                type="submit"
                variant="contained"
                color="error"
                disabled={!invoice}
                onClick={handleCancel}
              >
                Cancel
              </Button>
              &nbsp;
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={!invoice}
                onClick={handleApprove}
              >
                Complete Service
              </Button>
            </Box>
          </React.Fragment>
        )}
      </CardContent>
    </Card>
  );
}

export default CompleteService;
