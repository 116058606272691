import React, { useState, useEffect } from "react";
import { Navigate, useParams } from "react-router-dom";
import styled from "styled-components/macro";

import {
  Box,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  TextField as MuiTextField,
  Typography,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  InputAdornment,
  Autocomplete,
} from "@mui/material";
import { spacing } from "@mui/system";

// Quill imports
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";

import { Camera, Image, CameraOff, AlertCircle, Trash2 } from "react-feather";
import { useNavigate } from "react-router-dom";
import { apiurl } from "../../../config.js";
import { v4 as uuidv4 } from "uuid";

const axios = require("axios").default;

const Card = styled(MuiCard)(spacing);

const Button = styled(MuiButton)(spacing);

const TextField = styled(MuiTextField)(spacing);

const QuillWrapper = styled.div`
  .ql-editor {
    min-height: 200px;
  }
`;

function PhotoList(props) {
  if (props.photos.length < 1) {
    return (
      <List dense={true}>
        <ListItem key={0}>
          <ListItemIcon>
            <CameraOff />
          </ListItemIcon>
          <ListItemText primary="No photos" />
        </ListItem>
      </List>
    );
  } else {
    return (
      <List dense={true}>
        {props.photos.map((photo, index) => (
          <ListItem key={index}>
            <ListItemIcon>
              <Image />
            </ListItemIcon>
            <ListItemText primary={photo.name} />
          </ListItem>
        ))}
      </List>
    );
  }
}

function Evaluate(props) {
  const [isLoading, setLoading] = React.useState(false);
  const { id } = useParams();
  const [summary, setSummary] = useState("");
  const [cost, setCost] = useState();
  const [estimate, setEstimate] = useState();
  const [issues, setIssues] = useState([]);
  const [photos, setPhotos] = useState([]);
  const navigate = useNavigate();

  function handleFileUpload(e) {
    let file = e.target.files[0];
    const newName = uuidv4();
    const name = newName + file.name.substring(file.name.lastIndexOf("."));
    file = new File([file], name, { type: file.type });
    setPhotos((photos) => [...photos, file]);
  }

  function generate_issue_links() {
    var issues_links = [];

    for (var i = 0; i < issues.length; i++) {
      issues_links.push(apiurl + "/issues/" + issues[i].id + "/");
    }
    return issues_links;
  }

  function handleSubmit() {
    setLoading(true);
    axios
      .post(apiurl + "/evaluations/", {
        summary: summary,
        estimate_num: estimate,
        total_cost: cost,
        issues: generate_issue_links(),
        images: [],
        complete: true,
      })
      .then((response) => {
        updateTicket(response.data.id + "/");
        uploadPhotos(response.data.id, photos);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      })
      .then(() => {
        setLoading(false);
        navigate("/ticket/" + id);
      });
  }

  function uploadPhotos(evaluation_id) {
    for (var i = 0; i < photos.length; i++) {
      let data = new FormData();
      data.append("evaluation", apiurl + "/evaluations/" + evaluation_id + "/");
      data.append("image", photos[i], photos[i].name);

      axios
        .post(apiurl + "/evalimg/", data, {
          headers: {
            accept: "application/json",
            "Accept-Language": "en-US,en;q=0.8",
            "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
          },
        })
        .then((response) => {
          //handle success
        })
        .catch((error) => {
          //handle error
        });
    }
  }

  function updateTicket(evaluation_id) {
    axios
      .patch(apiurl + "/tickets/" + id + "/", {
        status: 3,
        evaluation: apiurl + "/evaluations/" + evaluation_id,
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }

  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h1" gutterBottom>
          Equipment Evaluation
        </Typography>
        {isLoading ? (
          <Box display="flex" justifyContent="center" my={6}>
            <CircularProgress />
          </Box>
        ) : (
          <form onSubmit={handleSubmit}>
            <Summary summary={summary} setSummary={setSummary} />
            <br />
            <Typography variant="h6" gutterBottom>
              Issues
            </Typography>
            <Issues updateIssues={setIssues} />
            <br />
            <Typography variant="h6" gutterBottom>
              Photos
            </Typography>
            <PhotoList photos={photos} />
            <Button variant="contained" component="label" disabled={false}>
              <Camera style={{ marginRight: "2px" }} />
              &nbsp;Add Photo
              <input
                id="file"
                accept="image/*"
                type="file"
                hidden
                multiple
                onChange={handleFileUpload}
              />
            </Button>
            <br />
            <TextField
              name="estimate"
              key="estimate-input"
              id="estimate"
              label="Estimate #"
              value={estimate}
              fullWidth
              onChange={(e) => {
                setEstimate(e.target.value);
              }}
              variant="outlined"
              my={2}
            />
            <TextField
              name="cost"
              key="cost-input"
              id="cost"
              label="Cost"
              value={cost}
              fullWidth
              onChange={(e) => {
                setCost(e.target.value);
              }}
              variant="outlined"
              my={2}
            />
            <br />
            <br />
            <Box
              style={{
                display: "flex",
                justifyContent: "right",
              }}
            >
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={false}
              >
                Submit Evaluation
              </Button>
            </Box>
          </form>
        )}
      </CardContent>
    </Card>
  );
}

export default Evaluate;

function Summary(props) {
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Summary
      </Typography>
      <Typography variant="body2" gutterBottom>
        Describe in detail all issues discovered with the equipment and any
        other related information.
      </Typography>
      <Box mt={3}>
        <QuillWrapper>
          <ReactQuill
            autoFocus
            theme="snow"
            fullWidth
            value={props.summary}
            onChange={props.setSummary}
            placeholder="Type something.."
          />
        </QuillWrapper>
      </Box>
    </React.Fragment>
  );
}

function Issues(props) {
  const [isLoading, setLoading] = useState(false);
  const [issueList, setIssueList] = useState([]);
  const [issueOptionList, setIssueOptionList] = useState([]);
  const [currentIssues, setCurrentIssues] = useState([]);

  // Autocomplete component state
  const [value, setValue] = React.useState("");
  const [inputValue, setInputValue] = React.useState("");

  useEffect(() => {
    setLoading(true);

    const fetchIssues = async () => {
      try {
        const { data: response } = await axios.get(apiurl + "/issues/");
        setIssueList(response);
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    };
    fetchIssues();
  }, []);

  // Save changes to parent state
  useEffect(() => {
    props.updateIssues(currentIssues); // Save to parent state
  }, [currentIssues]);

  useEffect(() => {
    var filteredOptions = issueList.filter(
      (item) => !currentIssues.includes(item)
    );

    const options = filteredOptions.map((issue) => ({
      label: issue.description,
      id: issue.id,
    }));
    setIssueOptionList(options);
  }, [issueList, currentIssues]);

  function addIssue(newissue) {
    var foundIssue = issueList.filter(function (issue) {
      return issue.id == newissue.id;
    });
    // Above returns an array with 1 object. Use [0] to access object directly.
    setCurrentIssues((currentIssues) => [...currentIssues, foundIssue[0]]);
    setInputValue(null);
    setValue(null);
  }

  function removeIssue(removeissue) {
    var newIssues = currentIssues.filter((issue) => {
      return issue.id !== removeissue.id;
    });
    setCurrentIssues(newIssues);
  }

  return (
    <React.Fragment>
      {currentIssues.length < 1 && (
        <List dense={true}>
          <ListItem key={0}>
            <ListItemIcon>
              <AlertCircle />
            </ListItemIcon>
            <ListItemText primary="No issues" />
          </ListItem>
        </List>
      )}
      {currentIssues.length > 0 && (
        <List dense={true}>
          {currentIssues.map((issue, index) => (
            <ListItem key={index}>
              <ListItemIcon>
                <AlertCircle />
              </ListItemIcon>
              <ListItemText
                primary={issue.description + " - " + issue.resolution}
              />
              <ListItemIcon>
                <Trash2
                  style={{ cursor: "pointer", color: "red" }}
                  onClick={() => removeIssue(issue)}
                />
              </ListItemIcon>
            </ListItem>
          ))}
        </List>
      )}
      <Autocomplete
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
          addIssue(newValue);
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        id="controllable-states-demo"
        options={issueOptionList}
        sx={{ width: 300 }}
        renderInput={(params) => <TextField {...params} label="Choose Issue" />}
      />
    </React.Fragment>
  );
}
