import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { useParams } from "react-router-dom";
import {
  Box,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  TextField as MuiTextField,
  Typography,
  CircularProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { spacing } from "@mui/system";

import { useNavigate } from "react-router-dom";
import { apiurl } from "../../../config.js";

const axios = require("axios").default;

const Card = styled(MuiCard)(spacing);

const Button = styled(MuiButton)(spacing);

const TextField = styled(MuiTextField)(spacing);

function UpdateIssue() {
  const [isLoading, setLoading] = React.useState(false);
  const [description, setDescription] = useState("");
  const [resolution, setResolution] = useState("");
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(apiurl + "/issues/" + id)
      .then((response) => {
        setDescription(response.data.description);
        setResolution(response.data.resolution);
      })
      .then(() => {
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  function handleSubmit() {
    setLoading(true);
    console.log("Submitting form to API...");

    axios
      .patch(apiurl + "/issues/" + id + "/", {
        description: description,
        resolution: resolution,
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        navigate("/issues");
      })
      .then((response) => {
        setLoading(false);
        console.log("Issue successfully updated.");
        navigate("/issues");
        console.log(response.data);
      });
  }

  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h1" gutterBottom>
          Update Issue
        </Typography>
        {isLoading ? (
          <Box display="flex" justifyContent="center" my={6}>
            <CircularProgress />
          </Box>
        ) : (
          <form onSubmit={handleSubmit}>
            <Typography variant="body2" gutterBottom>
              Clearly and briefly describe the issue and resoluion. Issues are
              meant to be reused across many models and manufactures.
            </Typography>
            <TextField
              autoFocus
              name="description"
              id="description"
              label="Description"
              value={description}
              error={!description}
              fullWidth
              onChange={(e) => {
                setDescription(e.target.value);
              }}
              variant="outlined"
              my={2}
            />
            <TextField
              name="resolution"
              id="resolution"
              label="Resolution"
              value={resolution}
              error={!resolution}
              fullWidth
              onChange={(e) => {
                setResolution(e.target.value);
              }}
              variant="outlined"
              my={2}
            />
            <Box
              style={{
                display: "flex",
                justifyContent: "right",
              }}
            >
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={!description || !resolution}
              >
                Update
              </Button>
            </Box>
          </form>
        )}
      </CardContent>
    </Card>
  );
}

export default UpdateIssue;
