import React from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";

import { Paper, Typography } from "@mui/material";

import SignInComponent from "../../components/auth/SignIn";

import DMGLogo from "../../vendor/dmg-logo.png";

const Centered = styled.div`
  text-align: center;
`;

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

function SignIn() {
  return (
    <React.Fragment>
      <Wrapper>
        <Helmet title="Sign In" />
        <Centered>
          <img
            src={DMGLogo}
            alt="Discovery Management Group"
            style={{
              width: "50%",
              margin: "auto",
            }}
          />
        </Centered>
        <Typography component="h2" variant="body1" align="center">
          Sign in to your account to continue
        </Typography>

        <SignInComponent />
      </Wrapper>
    </React.Fragment>
  );
}

export default SignIn;
