import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { useParams } from "react-router-dom";
import {
  Box,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  TextField as MuiTextField,
  Typography,
  CircularProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { spacing } from "@mui/system";

import { useNavigate } from "react-router-dom";
import { apiurl } from "../../../config.js";

const axios = require("axios").default;

const Card = styled(MuiCard)(spacing);

const Button = styled(MuiButton)(spacing);

const TextField = styled(MuiTextField)(spacing);

function ManufacturerAdd() {
  const [isLoading, setLoading] = React.useState(false);
  const [name, setName] = useState("");
  const navigate = useNavigate();

  function handleSubmit() {
    setLoading(true);
    console.log("Submitting form to API...");

    axios
      .post(apiurl + "/manufacturers/", {
        name: name,
        products: [],
      })
      .then((response) => {
        setLoading(false);
        console.log("Manufacturer successfully updated.");
        navigate("/manufacturers");
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }

  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h1" gutterBottom>
          Add Manufacturer
        </Typography>
        {isLoading ? (
          <Box display="flex" justifyContent="center" my={6}>
            <CircularProgress />
          </Box>
        ) : (
          <form onSubmit={handleSubmit}>
            <TextField
              autoFocus
              name="name"
              id="name"
              label="Name"
              value={name}
              error={!name}
              fullWidth
              onChange={(e) => {
                setName(e.target.value);
              }}
              variant="outlined"
              my={2}
            />
            <br />
            <br />
            <Box
              style={{
                display: "flex",
                justifyContent: "right",
              }}
            >
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={!name}
              >
                Save
              </Button>
            </Box>
          </form>
        )}
      </CardContent>
    </Card>
  );
}

export default ManufacturerAdd;
